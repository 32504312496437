import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../layout"
import Feature from "../feature"
import ResponsiveSpacing from "../responsive-spacing"
import NutritionFeature from "../flavors/nutrition-feature"
import Heading from "../heading"
import HeroArea from "./hero"
import Flavor from "../flavor"
import Banner from "../banner"
import QuoteBanner from "../quote-banner"
import BottomColorNav from "../bottom-color-nav"
import Button from "../button"
import SVG from "../svg"
import SEO from "../seo"
import CTA from "../cta"
import { connect } from "react-redux"
import postPath from "../../utils/post-path"
import { Row, Col } from "../rows"
import Garnish from "../garnish"
import RoughEdge from "../rough-edge"
import PromoSticker from "../promo-sticker"

const ContentStyled = styled.div`
  .heroButton {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .orgCert {
    position: absolute;
    right: 50%;
    margin-right: -650px;
    height: 148px;
    top: -100px;
    transform: rotate(25deg);
  }

  .linkout {
    color: ${(p) => p.fields.hero_area.link_color || p.theme.DarkBlue};
    margin-left: 20px;
    text-decoration: none;
  }

  .similar {
    margin-bottom: 150px;
  }

  .certs {
    background: ${(p) => p.theme.White};
    position: relative;
    z-index: 1;
    > .inner {
      min-height: 125px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      text-align: center;
      max-width: 600px;
      margin: 0 auto;

      @media (max-width: 768px) {
        padding: 20px 0;
        flex-wrap: wrap;
        .icon {
          width: 30%;
        }
      }
    }
  }

  .certs .icon {
    margin: 10px auto;
    height: 50px;
  }

  .similarCol {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .explore {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }
`

const Content = (props) => {
  const { pack, dispatch, data } = props
  const post = data.flavor.edges[0].node
  const fields = post.acf
  const allFlavors = data.similar_flavors.edges.map((e) => e.node)
  let recipe, recipeId
  if (fields.recipe.featured_recipes) {
    recipeId = fields.recipe.featured_recipes[0].wordpress_id
    recipe = data.recipes.edges
      .map((e) => e.node)
      .filter((r) => r.wordpress_id === recipeId)[0]
  }
  let similar_items = []
  if (fields.similar_items.selected_flavors) {
    const similar_item_ids = fields.similar_items.selected_flavors.map(
      (s) => s.wordpress_id
    )
    allFlavors.map((f) => {
      similar_item_ids.map((sii) => {
        if (f.acf.flavor.not_for_sale) return
        if (f.wordpress_id === sii) {
          similar_items.push(f)
        }
      })
    })
  }
  const image = fields.flavor.carton_image.localFile.childImageSharp.fixed.src
  const garnishes = fields.garnish || []

  const recipeHTML = recipe ? (
    <div className="recipe">
      <Heading margin="150px 0 50px 0">
        <h2>{fields.recipe.headline}</h2>
        <h3>{fields.recipe.sub_heading}</h3>
      </Heading>
      <Feature
        align="right"
        bg={true}
        title={recipe.title}
        summary={recipe.acf.summary}
        image={recipe.acf.sharing.image}
        button_label={"GET RECIPE"}
        wave_color={"White"}
        button_link={postPath("/recipe/", recipe.title)}
      />
    </div>
  ) : (
    ""
  )

  const siHTML =
    similar_items.length > 0 ? (
      <div className="similar">
        <Heading margin="150px 0 0 0">
          <h2>{fields.similar_items.headline}</h2>
          <h3>{fields.similar_items.sub_heading}</h3>
        </Heading>
        <Row columns="1fr 1fr 1fr" width="1100px" margin="0 auto">
          {similar_items.map((si, i) => {
            return (
              <Col className="similarCol" key={i}>
                <Flavor
                  {...si.acf.flavor}
                  dispatch={dispatch}
                  key={i}
                  index={i}
                  fields={si.acf}
                />
              </Col>
            )
          })}
        </Row>
        <div className="explore">
          <Button to="/flavors/">{fields.similar_items.button_label}</Button>
        </div>
      </div>
    ) : (
      ""
    )

  const {
    search_title,
    share_headline,
    search_description,
    share_comment,
    share_image,
    flavor,
  } = fields

  const { not_for_sale, promotion, price, name, sku } = flavor

  const product = {
    name,
    image,
    sku,
    price,
  }

  return (
    <ContentStyled fields={fields}>
      <SEO
        title={search_title || name}
        description={search_description}
        headline={share_headline || name}
        share_comment={share_comment}
        image={share_image || image}
      />
      <HeroArea fields={fields}>
        <div className="price">${fields.flavor.price}</div>
        <div tabIndex="0" className="heroButton">
          {promotion === "sold-out" ? (
            <Button icon="+" disabled={true}>
              ADD TO PACK
            </Button>
          ) : (
            <Button
              icon="+"
              onClick={(e) => {
                e.preventDefault()
                dispatch({ type: "ADD_TO_PACK", product })
              }}
            >
              {fields.hero_area.add_button_label}
            </Button>
          )}
          <Link className="linkout" to={fields.hero_area.link_url}>
            <CTA color={fields.hero_area.link_color}>
              {fields.hero_area.link_label}
            </CTA>
          </Link>
        </div>
        <PromoSticker promotion={promotion} />
      </HeroArea>

      <div className="certs">
        <RoughEdge className="top" color={"white"} />
        <div className="inner">
          {fields.certifications.map((c, i) => (
            <SVG
              key={i}
              name={c}
              className="icon"
              color={fields.flavor.color_1}
            />
          ))}
        </div>
      </div>
      <Banner inner={fields.opening_statement} color={fields.flavor.color_1} />

      <NutritionFeature fields={fields}>
        <Garnish
          garnish={garnishes[0]}
          pSpeed={-0.2}
          top={300}
          width={150}
          rotation={0}
          rSpeed={0}
          pos={"left"}
        />
      </NutritionFeature>
      <ResponsiveSpacing>
        <Feature
          align="left"
          margin={"150px auto"}
          title={fields.feature.headline}
          sub_heading={fields.feature.sub_heading}
          summary={fields.feature.summary}
          image={fields.feature.image}
          button_label={fields.feature.button_label}
          wave_color={fields.feature.wave_color || fields.flavor.color_1}
          button_link={fields.feature.button_link}
        />
        <SVG
          name="OrganicCert"
          className="orgCert"
          color={fields.flavor.color_2}
        />
      </ResponsiveSpacing>
      <QuoteBanner
        quote={fields.quote.quote_text}
        quoted={fields.quote.quoted}
        text_color={fields.quote.color_override || fields.flavor.color_1}
        color={fields.flavor.color_2}
      >
        <Garnish
          garnish={garnishes[2]}
          pSpeed={-0.2}
          top={-200}
          width={132}
          rotation={-55}
          rSpeed={-0.02}
          pos={"left"}
          style={{}}
        />
        <Garnish
          garnish={garnishes[1]}
          pSpeed={-0.2}
          top={-500}
          width={192}
          rotation={100}
          rSpeed={0.05}
          pos={"right"}
          style={{}}
        />
      </QuoteBanner>
      <ResponsiveSpacing>{recipeHTML}</ResponsiveSpacing>
      {siHTML}
      <BottomColorNav />
    </ContentStyled>
  )
}

const ConnectedContent = connect((state) => state)(Content)

export default (props) => {
  return (
    <Layout>
      <ConnectedContent data={props.data} context={props.pageContext} />
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    flavor: allWordpressWpFlavors(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          title
          slug
          content
          acf {
            flavor {
              sku
              name
              name_with_html
              price
              not_for_sale
              color_1
              color_2
              promotion
              carton_image {
                localFile {
                  childImageSharp {
                    fixed(width: 450, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
            }
            hero_area {
              top_line
              headline
              add_button_label
              link_label
              link_url
              top_line_color
              headline_color
              wave_color
              link_color
              mobile_background {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2800, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              background {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2800, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            certifications
            opening_statement
            nutrition_feature {
              headline
              summary
              button_label
              button_link
              wave_color
              color_override
              image {
                localFile {
                  childImageSharp {
                    fluid(maxHeight: 450, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            feature {
              headline
              sub_heading
              summary
              button_label
              button_link
              wave_color
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            search_title
            search_description
            share_headline
            share_comment
            share_image {
              source_url
            }
            quote {
              quote_text
              quoted
              color_override
            }
            recipe {
              headline
              sub_heading
              featured_recipes {
                wordpress_id
              }
            }
            similar_items {
              headline
              sub_heading
              selected_flavors {
                wordpress_id
              }
              button_label
            }
            garnish {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 178, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    similar_flavors: allWordpressWpFlavors {
      edges {
        node {
          wordpress_id
          title
          acf {
            flavor {
              name
              sku
              color_1
              color_2
              name_with_html
              price
              not_for_sale
              promotion
              carton_image {
                source_url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              inside_image {
                source_url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            nutrition_feature {
              headline
              summary
              button_label
              button_link
              wave_color
              color_override
              image {
                localFile {
                  childImageSharp {
                    fluid(maxHeight: 450, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    recipes: allWordpressWpRecipes {
      edges {
        node {
          title
          wordpress_id
          acf {
            summary
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 450, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            sharing {
              headline
              description
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 450, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
