import React from "react"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import SVG from "../svg"
import BreadCrumbs from "../breadcrumbs"
import HeroRow from "../hero-row"

const HeroAreaStyled = styled.div`
  position: relative;
  max-width: 2560px;
  margin: 93px auto 0 auto;

  @media (max-width: 768px) {
    min-height: 650px;
  }

  .background {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    max-width: 2560px;
    height: 690px;
    margin: 0 auto;
    top: 0;
    z-index: 1;
    position: absolute;
    width: 100%;

    @media (max-width: 768px) {
      height: auto;
    }
  }

  .gatsby-image-wrapper {
    width: 100%;
    min-height: 690px;
  }

  .topline {
    color: ${(p) => p.fields.hero_area.top_line_color || p.theme.DarkBlue};
  }

  .headline {
    color: ${(p) =>
      p.fields.hero_area.headline_color !== ""
        ? p.fields.hero_area.headline_color
        : p.fields.flavor.color_1};

    @media (max-width: 768px) {
      font-size: 5em;
    }
  }

  .price {
    margin: 10px 0 0;
    text-align: left;
    font-size: 2.4em;
    color: ${(p) => p.theme.DarkBlue};
    font-family: "Montserrat";
  }
`

const HeroArea = ({ fields, children }) => {
  const trail = [
    {
      label: "FLAVORS",
      link: "/flavors/",
    },
    {
      label: fields.flavor.name,
      link: "",
    },
  ]

  const sources = fields.hero_area.mobile_background
    ? [
        {
          media: "(min-width: 768px)",
          ...fields.hero_area.background.localFile.childImageSharp.fluid,
        },
        {
          media: "(max-width: 768px)",
          ...fields.hero_area.mobile_background.localFile.childImageSharp.fluid,
        },
      ]
    : fields.hero_area.background
    ? [
        {
          ...fields.hero_area.background.localFile.childImageSharp.fluid,
        },
      ]
    : []

  return (
    <HeroAreaStyled fields={fields}>
      <div className="background">
        <Img
          objectFit="cover"
          objectPosition="50% 100%"
          alt={fields.headline}
          imgStyle={{
            zIndex: 1,
          }}
          fluid={sources}
        />
      </div>
      <HeroRow>
        <BreadCrumbs trail={trail} />
        <h1 className="heroH1">
          <div
            className="topline"
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: fields.hero_area.top_line,
            }}
          />
          <div
            className="headline"
            dangerouslySetInnerHTML={{
              __html: fields.hero_area.headline,
            }}
            tabIndex="0"
          />
          <SVG
            name="SWave"
            color={fields.hero_area.wave_color || fields.flavor.color_2}
          />{" "}
          {children}
        </h1>
      </HeroRow>
    </HeroAreaStyled>
  )
}

export default HeroArea
